import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortStatus',
})
export class SortStatusPipe implements PipeTransform {
  transform(statusList: any[]): any[] {
    if (!statusList || statusList.length === 0) {
      return [];
    }

    return statusList.slice().sort((a, b) => {
      const dateA = new Date(`${a.intransitDate} ${a.intransitTime}`);
      const dateB = new Date(`${b.intransitDate} ${b.intransitTime}`);
      return dateB.getTime() - dateA.getTime(); // Descending order
    });
  }
}
