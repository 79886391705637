export const PATTERNS = {
  SUB_DOMAIN: /(?:[\s.])([a-z0-9][a-z0-9-]+[a-z0-9]\.auperator\.co)/,
  SUB_DOMAIN_STRING: /^[a-z0-9_-]+$/,
  // tslint:disable-next-line:max-line-length
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  EMAIL_EXCLUDE_ESHOPBOX: /^(?!.*@eshopbox\.com)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[1-9]{1}[0-9]{9}$/,
  PAN_CARD: /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}?$/,
  SLUG: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
  //   PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{3,}$/,
  PINCODE: /^[1-9][0-9]{5}$/,
  NUMBER: /^[0-9]*$/,
  STRING: /^[A-Za-z]/,
  URL: /\./,
  IFSC: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
  GSTIN: /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/
};


export const VALIDATION_MESSAGES = {
  fullName: [{ type: 'required', message: 'Enter Your Full Name' }],
  firstName: [{ type: 'required', message: 'Enter First Name' }],
  lastName: [{ type: 'required', message: 'Enter Last Name' }],
  email: [
    { type: 'required', message: 'Enter an Email' },
    { type: 'pattern', message: 'Enter a valid Email address' }
  ],
  phoneNumber: [
    { type: 'required', message: 'Enter a Phone Number' },
    { type: 'pattern', message: 'Please enter 10 digit phone number.' }
  ],
  confirmPassword: [
    { type: 'required', message: 'Enter a Confirm password' },
    { type: 'notEqual', message: 'The password does not match' }
  ],
  password: [
    { type: 'required', message: 'Enter a Password' },
    {
      type: 'minlength',
      message: 'The password length should be atleast 8 characters long'
    },
    {
      type: 'pattern',
      message: 'Kindly use combination of Alphanumeric and special characters'
    }
  ]
}