import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import * as feather from 'feather-icons';
import { camelCase, error } from 'jquery';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';
import { PATTERNS } from 'src/app/constants/constants/constants';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { MasterRoutingModule } from '../../master-routing.module';
import { ViewDialogboxComponent } from 'src/app/shared/component/view-dialogbox/view-dialogbox.component';
import { MasterService } from 'src/app/shared/services/master.service';
// import { CamelcasePipe } from 'src/app/_pipe/camelcase.pipe';
@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css'],
  providers: []
})

export class VendorComponent implements
  OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<vendorTable>();
  allCheckedChange: Subject<boolean> = new Subject<boolean>();
  allChecked: boolean = false;
  FilterForm = new FormGroup({
    OrderStatus: new FormControl(''),
    OrderNumber: new FormControl(''),
    ExOrdernumber: new FormControl(''),
    dateRange: new FormControl('')
  });
  floatLabel: FloatLabelType = 'auto';
  // floatLabel: FloatLabelType = 'auto' as FloatLabelType;

  activeClass: boolean = false;
  orderData: any = [];
  showFilter: any;

  length = 50;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = false;
  showFirstLastButtons = false;
  disabled = false;

  pageEvent: any;

  vendorData: any = [];


  dateOptionsList = [
    {
      viewValue: 'Today',
      value: 'today'
    },
    {
      viewValue: 'Yesterday',
      value: 'yesterday'
    },
    {
      viewValue: 'Last 7 days',
      value: 'last7days'
    },
    {
      viewValue: 'Last 30 days',
      value: 'last30days'
    },
    // {
    //   viewValue: 'Last 90 days',
    //   value: 'last90days'
    // },
    // {
    //   viewValue: 'This week',
    //   value: 'thisweek'
    // },
    {
      viewValue: 'This month',
      value: 'thismonth'
    },
    {
      viewValue: 'Last month',
      value: 'lastmonth'
    }
  ];

  fromDateObj: any = {};
  toDateObj: any = {};
  customDate: any;
  AllStatusList: any = [];
  filterOrderstatus: any;
  allCheckedOrderNumbers: string = '';
  activeParam: any;
  pendingParam: any;
  loader: boolean = false;
  getpayload: any = []
  PATTERNS = PATTERNS
  vendorForm: FormGroup | any;
  vendorBranchForm: FormGroup | any;
  totalCount: number = 0;
  vendorFilter: any = [];

  constructor(public dialog: MatDialog, private renderer: Renderer2, private auth: AuthService, private datePipe: DatePipe,
    private route: ActivatedRoute, private fb: FormBuilder, private http: HttpClient, private router:MasterRoutingModule, private masterService: MasterService) {
    this.allCheckedChange.subscribe(value => {
      this.allChecked = value;
    });

    this.vendorForm = this.fb.group({
      vendorName: ['', Validators.required],

      email: ['',[Validators.required,Validators.pattern(PATTERNS.EMAIL)]],
      reportsEmail:  ['',[Validators.required,Validators.pattern(PATTERNS.EMAIL)]],
      groupId: [''],
      activeStatus: ['', Validators.required],
      // Branch details
      branchName: ['', Validators.required],
      phoneNo: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      erpId: [''],
      addressCode: ['', Validators.required],
      gst: ['', [Validators.required, Validators.pattern(PATTERNS.GSTIN)]],
      pan: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
      // cwId: ['', Validators.required],
      // branches: ['', Validators.required],
      // logoUpload: ['', Validators.required],
      // billingName: ['', Validators.required],
      // documentStorage: ['', Validators.required]
    });

    this.vendorBranchForm = this.fb.group({
      vendorBranchName: ['', Validators.required],
      vendorBranchAddress: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      gst: ['', [Validators.required, Validators.pattern(PATTERNS.GSTIN)]],
      pan: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
      cwId: ['', Validators.required],
      status: ['', Validators.required]
    })
  }

  getVendor(pageIndex: any, pageSize: any) {
    this.loader = true;
    this.masterService.getVendorDetails( pageIndex, pageSize, this.vendorFilter).subscribe((data:any) => {
      // console.log(data);
      if (data != null) {
        this.vendorData = data?.responseObject?.content;
        this.totalCount = data?.responseObject?.totalElements;
        localStorage.setItem('vendor', JSON.stringify(this.vendorData));
      }
      this.loader = false;
    })
  }

  redirectToVendorBranch(vendor:any){
    const vendorId = vendor.id; 
    // this.router.navigate([`/vendor-branch`]);
    // this.router.navigate([`vendor/vendor-branch`]);
    this.router.navigate(
      ['master/vendor-branch'],
      // { queryParams: { active: true} }
    );
  }

 
  ngOnInit(): void {
    // this.getOderData(this.getpayload, this.pageIndex, this.pageSize);

    this.getVendor(this.pageIndex, this.pageSize);

  }
  logOut() {
    // console.log("logout")
    this.auth.logOut()
  }

  pageNavigations(e: PageEvent) {
    // debugger
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    console.log(this.pageEvent, this.length, this.pageIndex, this.pageIndex)
    this.allChecked = false
    this.getVendor(this.pageIndex, this.pageSize);
  }
  
  /**@description Method To Autofill Dates*/
  public onChangeCondition(dateKey: any) {
    console.log('Selected value:', dateKey);

    let fromDate: any;
    let toDate: any;
    // this.filterOptionsList.filter(x => x.value === filterCondition)[0].customDates = {};
    switch (dateKey) {
      case 'today':
        fromDate = new Date();
        // toDate = new Date();
        break;
      case 'yesterday':
        fromDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'days')
            .startOf('day')
            .valueOf()
        );
        break;
      case 'last7days':
        fromDate = new Date(
          moment()
            .subtract(6, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'last30days':
        fromDate = new Date(
          moment()
            .subtract(29, 'days')
            .startOf('day')
            .valueOf()
        );
        toDate = new Date();
        break;
     
      case 'thismonth':
        fromDate = new Date(
          moment()
            .startOf('month')
            .valueOf()
        );
        toDate = new Date();
        break;
      case 'lastmonth':
        fromDate = new Date(
          moment()
            .subtract(1, 'months')
            .startOf('month')
            .valueOf()
        );
        toDate = new Date(
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .valueOf()
        );
        break;
    }
    const FROM_DATE = this.datePipe.transform(fromDate, 'dd-MM-yyyy Z', '+0530');
    const TO_DATE = this.datePipe.transform(toDate, 'dd-MM-yyyy Z', '+0530');
    // console.log("dateKey",dateKey,TO_DATE)

    this.fromDateObj = FROM_DATE
    if (dateKey != 'today') {
      this.toDateObj = TO_DATE
    }

    console.log("FROM_DATE", FROM_DATE, "TO_DATE", TO_DATE)
    console.log("FROM_DATE", this.fromDateObj, "TO_DATE", this.toDateObj)
  }


  resetFilter() {
    // this.showFilter = !this.showFilter
    this.FilterForm.reset();
    this.filterOrderstatus = '';
    this.fromDateObj = '';
    this.toDateObj = '';
    // this.getOderData(this.getpayload, this.pageIndex, this.pageSize)

  }
  viewAllOrder() {
    this.resetFilter()
  }
  submitFilter() {
    console.log(this.fromDateObj);
    console.log(this.toDateObj);
    console.log(this.FilterForm.value.OrderStatus)

    var array = this.FilterForm.value.OrderStatus;
    if (Array.isArray(array)) {
      // Convert the array to a string with values separated by commas
      this.filterOrderstatus = array.join(',');

      console.log(this.filterOrderstatus);
    } else {
      console.log('OrderStatus is not an array.');
    }

    const obj = [];

    if (this.filterOrderstatus) {
      obj.push({
        "key": "STATUS_IN",
        "value": this.filterOrderstatus
      });
    }

    if (this.FilterForm.value.OrderNumber) {
      obj.push({
        "key": "ORDER_NUMBER_IN",
        "value": this.FilterForm.value.OrderNumber
      });
    }

    if (Object.keys(this.fromDateObj).length > 0) {
      obj.push({
        "key": "FROM_DATE",
        "dateValue": this.fromDateObj
      });
    }

    if (Object.keys(this.toDateObj).length > 0) {
      obj.push({
        "key": "TO_DATE",
        "dateValue": this.toDateObj
      });
    }

    // obj now contains only the objects with values

    console.log(obj)
    // this.getOderData(obj, this.pageIndex, this.pageSize)

  }

  emitDates(customDates: any) {
    console.log("ssss", customDates)
    this.customDate = customDates
  }

  toggleAllCheckboxes(checked: boolean): void {
    for (const vendor of this.vendorData.result) {
      vendor.checked = checked;
    }
  }

  addVendorForm(): void {
    let dialogRef = this.dialog.open(AddDialogboxComponent, {
      width: '648px',
      data: {
        "component": "vendor",
        "vendorForm": this.vendorForm
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getVendor(this.pageIndex, this.pageSize);
    })
  }

  viewEditVendorForm(data:any): void {
    this.vendorForm.setValue = data;
    let dialogRef = this.dialog.open(ViewDialogboxComponent, {
      width: '648px',
      data: {
        "component": "vendor",
        "vendorForm": this.vendorForm
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getVendor(this.pageIndex, this.pageSize);
    })
  }

  downloadAllExcel() {
    const data = [
      ['S.No', 'Vendor Name', 'Id', 'Email', 'Reports email','CW Group Code', 'Updated On', 'Created By', 'Created On', 'Status']
    ];

    this.vendorData?.forEach((vendor: any, index: number) => {
      data.push([
        index + 1,
        vendor.vendorName,
        vendor.id,
        vendor.email,
        vendor.reportsEmail,
        vendor.cwGroupCode,
        vendor.UpdatedAt,
        vendor.createdBy,
        vendor.createdAt,
        vendor.status.toString() === '1' ? 'Active' : 'Inactive'
      ]);
    });

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'AllVendors');
    XLSX.writeFile(wb, 'all-vendors.xlsx');
  }


  ngAfterViewInit() {
    feather.replace();
  }
}

export interface vendorTable {
  id: any;
  branchDetailsList: any;
  state_Id: any;
  modified_On: any;
  active_Ind: any;
  modified_By: any;
  email: any;
  cw_Group_Id: any;
  created_By: any;
  created_On: any;
  reports_Email: any;
  vendor_Name: any;
}


export interface VendorDetails {
  id: number;
  branchDetailsList: {
    id: number;
    key: any;
    vendorId: number;
    branchName: string;
    branchAddress: string;
    city: string;
    stateId: string;
    mobileNo: string;
    pincode: string;
    panNo: string;
    gstNo: string;
    cwId: string;
    activeInd: string;
    createdBy: number;
    createdOn: string;
    modifiedBy: number;
    modifiedOn: any;
  }[];
  state_Id: any;
  email: string;
  cw_Group_Id: string;
  created_By: any;
  modified_On: any;
  created_On: string;
  active_Ind: string;
  modified_By: number;
  vendor_Name: string;
  reports_Email: string;
}

