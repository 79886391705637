import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { PATTERNS } from 'src/app/constants/constants/constants';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import * as feather from 'feather-icons';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})

export class CreateUserComponent implements OnInit {
  fieldHide: boolean = true;

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];
  pageEvent: any;

  editingMode = false;
  checkUser: boolean = false;

  selectCustomer: any[] = [];
  updateCustomer: any[] = [];
  customerFilter: any = [];
  customerData: any = [];
  totalCount: number = 0;
  loader: boolean = false;
  passwordDoesNotMatch = false;
  createUserform = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl(''),
    user: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.pattern(PATTERNS.EMAIL)]),
    mobile: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]),
    userRole: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required,this.passwordMatchValidator.bind(this)]),
    status: new FormControl(''),
    selectedCustomer: new FormControl('', [Validators.required])
  });
  submitted: boolean = false;
  submittedloader: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateUserComponent>, private AuthService: AuthService, private masterService: MasterService, private snackbarService: SnackbarService) {

  }
  isPasswordVisible = false;

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  openSnackBar(message: string) {
    this.snackbarService.showSnackbar(message);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  getCustomer(pageIndex: any, pageSize: any) {
    this.loader = true;
    this.masterService.getCustomerDetails(pageIndex, pageSize, this.customerFilter).subscribe((data) => {
      console.log(data);
      if (data != null) {
        this.customerData = data?.responseObject?.content;
        this.totalCount = data?.responseObject?.totalElements;
      }
      this.loader = false;
    })
  }


  ngOnInit(): void {
    // debugger
    this.getCustomer(this.pageIndex, this.pageSize)

    if (this.data && this.data?.userData) {
      console.log(this.data, this.data.userData.status)
      this.fieldHide = false;
      // Patch the values into the form
      this.createUserform.patchValue({
        firstName: this.data?.userData?.firstName,
        lastName: this.data?.userData?.lastName,
        email: this.data?.userData?.email,
        mobile: this.data?.userData?.mobile,
        user: this.data?.userData?.userName,
        userRole: this.data?.userData ? this.data?.userData?.userRole : null,
        status: this.data?.userData?.status == 1 ? "Active" : "Inactive",
        selectedCustomer: this.data?.userData?.userRole === 'ROLE_SUPERUSER' || this.data?.userData?.userRole === '' ? 'all' : this.data?.userData?.clientList[0].clientId 
      });
      this.editingMode = true;
      this.selectCustomer = this.data?.userData?.clientList?.length>0 ? this.data?.userData?.clientList : []
      if (this.selectCustomer.length === 1) {
        this.selectCustomer[0].userId === this.data?.userData?.id
      }
      if (this.createUserform.controls.user.value) {
        this.createUserform.controls['user'].disable();
      }
      if (this.createUserform.controls.email.value) {
        this.createUserform.controls['email'].disable();
      }
      if (this.createUserform.controls.firstName.value) {
        this.createUserform.controls['firstName'].disable();
      }
      if (this.createUserform.controls.lastName.value) {
        this.createUserform.controls['lastName'].disable();
      }
    }
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = this.createUserform?.get('password')?.value;
    const confirmPassword = control.value;
  
    if (password && confirmPassword && password !== confirmPassword) {
      return { mismatch: true };
    }
    return null;
  }
  

  public checkPasswordMatch() {
    const passwordControl = this.createUserform.get('password');
    const confirmPasswordControl = this.createUserform.get('confirmPassword');

    if (passwordControl && confirmPasswordControl) {
      this.passwordDoesNotMatch = passwordControl.value !== confirmPasswordControl.value;

      if (!this.passwordDoesNotMatch) {
        confirmPasswordControl.setErrors(null);
      }
    }
  }
  
  get form(): { [key: string]: AbstractControl } {
    return this.createUserform.controls;
  }
  onSubmit(createUserform: any) {
   
    // this.createUserform.value.status == 'Active' ? 1 : 0
    this.submitted = true;

    // console.log(createUserform.value)
    const createobj = {
      "deviceId": "string",
      "email": this.createUserform.value.email,
      "firstName": this.createUserform.value.firstName,
      "fullName": this.createUserform.value.firstName + ' ' + this.createUserform.value.lastName,
      "lastName": this.createUserform.value.lastName,
      "mobile": this.createUserform.value.mobile,
      "password": this.createUserform.value.confirmPassword,
      "provider": "local",
      "user": this.createUserform.value.user,
      // "user":this.createUserform.value.firstName?.toLowerCase()  + '.' + this.createUserform.value.lastName?.toLowerCase(),
      "userGroup": "NORMAL_USER",
      "userRole": this.createUserform.value.userRole,
      "platform": 'ptl',
      "userClientMapping": this.selectCustomer,
      "status": 1
    }

    const updateobj = {
      "deviceId": "string",
      "email": this.createUserform.value.email ? this.createUserform.value.email : this.data?.userData?.email,
      "firstName": this.createUserform.value.firstName ? this.createUserform.value.firstName : this.data?.userData?.firstName,
      "lastName": this.createUserform.value.lastName ? this.createUserform.value.lastName : this.data?.userData?.lastName,
      "fullName": this.createUserform.value.firstName ? this.createUserform.value.firstName + this.createUserform.value.lastName : this.data?.userData?.fullName,
      "mobile": this.createUserform.value.mobile ? this.createUserform.value.mobile : this.data?.userData?.mobile,
      // "password": this.createUserform.value.password,
      "platform": "ptl",
      "provider": "local",
      "user": this.createUserform.controls.user.value ? this.createUserform.controls.user.value : this.data?.userData?.user,
      "userGroup": "NORMAL_USER",
      "userRole": this.createUserform.value.userRole,
      "status": this.createUserform.value.status == 'Active' ? 1 : 0,

      "userClientMapping": this.updateCustomer
    }
    if (this.data?.edituser) {
      this.createUserform.get('password')?.removeValidators(Validators.required);
      this.createUserform.get('password')?.updateValueAndValidity();
    }

    if (this.createUserform.valid) {
      this.submittedloader = true
      if (this.data?.edituser) {
        console.log(createUserform)

        this.AuthService.updateUser(updateobj).subscribe((res: any) => {
          if (res) {
            const snackbarMessage = 'User updated successfully'
            this.openSnackBar(snackbarMessage);
            this.dialogRef.close();
            console.log(res)
          }
          else{
            const snackbarMessage = res?.responseMessage
            this.openSnackBar(snackbarMessage);
          }
        })
      } else {
        this.AuthService.createUser(createobj).subscribe((res: any) => {
          if (res) {
            const snackbarMessage = 'User created successfully'
            this.openSnackBar(snackbarMessage);
            this.dialogRef.close();
            console.log(res)
          }
          else{
            const snackbarMessage = res?.responseMessage
            this.openSnackBar(snackbarMessage);
          }
        })
      }
    }
    else {
      this.loader = false;
      this.markFormGroupTouched(this.createUserform);
      const snackbarMessage = 'please enter details'
      this.openSnackBar(snackbarMessage);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  pageNavigations(e: PageEvent) {
    // console.log(this.activeParam, this.pendingParam)
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    // this.allChecked = false;

  }

  checkUserExit() {
    const user = this.createUserform.value.user
    this.AuthService.checkUser(user).subscribe((res: any) => {
      if (res?.responseMessage == "Success") {
        console.log(res?.responseMessage)
        this.checkUser = true
      }
    }, error => {
      this.checkUser = false

    })
  }

  Customer(event: any) {
    this.selectCustomer = [{
      status: 1,
      clientId: event.value,
      userId: this.data?.edituser ? this.data.userData.id : 0,
      id: 0,
      platform:'ptl'
    }],
    this.updateCustomer = [{
      status: 1,
      clientId: event.value,
      userId: this.data?.edituser ? this.data.userData.id : 0,
      id: this.data?.userData?.clientList[0]?.id,
      platform:'ptl'
    }]
  }
  selectRole(event: any) {
    // if(event.value === 'ROLE_SUPERUSER'){
    this.createUserform.patchValue({
      selectedCustomer: event.value === 'ROLE_SUPERUSER' || event.value === '' ? 'all' : ''
    });
    // }
  }
}
