<div class="page-wrapper">
    <div class="content">
        <div class="order-details">
            <div class="content">
                <div class="page-header">
                    <div class="page-title">
                        <h4>Docket Status</h4>
                    </div>
                </div>
                

                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="docketForm">
                            <div class="table-top position-relative">
                                <div class="search-set w-100 ">
                                    <div class="search search-path page-header d-flex justify-content-center  mt-4">
                                        <span class="text">
                                            Docket Number:
                                        </span>
                                        <span class="input-width">
                                            <input placeholder="Enter docket number" class="form-control" 
                                                formControlName="docketNo" keyup.enter>
                                        </span>
                                        <button class="btn btn-added" (click)="searchByDocketNo()">
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr>
                        <!-- TABLE -->
                        <div class="table-responsive d-flex justify-content-center none">
                            <ul class="order-data">
                                <li class="table-head d-flex justify-content-center">
                                    <span>Docket No.</span>
                                    <span>Pickup Date</span>
                                    <span class="custom-field">Booking City</span>
                                    <span>Delivery City</span>
                                    <span>Status</span>
                                    <span class="custom-field">Docket Current Status</span>
                                </li>

                                <div class="d-flex justify-content-center p-1">
                                    <ng-container>
                                        <li *ngFor="let docket of custMappData; let i = index">
                                            <span class="tracking"><a>{{docket?.docketNo}}</a></span>
                                            <span>{{docket?.pickupDate | date:'dd/MM/yy'}}</span>
                                            <span class="custom-field">{{docket?.bookingCity | camelcase}}</span>
                                            <span>{{docket?.docketStatus}}</span>
                                            <span>{{docket?.deliveryCity | camelcase}}</span>
                                            <span class="custom-field">{{docket?.docketCurrentStatus |
                                                camelcase}}</span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="custMappData && custMappData?.exceptionMessage">
                                        <!-- <p></p> {{custMappData?.exceptionMessage}} -->
                                        <div class="no-order text-center pt-4 pb-4">
                                            <p class="margin-0">No dockets found</p>
                                            <a class="btn btn-primary">View all dockets</a>
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- 90431537 -->
                                <!-- 124060407 -->
                            </ul>
                        </div>
                        <div *ngIf="loader">
                            <div class="loader">
                                <mat-spinner [diameter]="25" [strokeWidth]="2" class="centre-align"></mat-spinner>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="card scroll-main">

                                <div class="card-body" *ngFor="let docket of custMappData">

                                    <div class="tracking-details">
                                        <ul>
                                            <li class="row-heading">
                                                <span>Docket No.</span>
                                                <span class="w-15">Pickup Date</span>
                                                <span class="custom-field">Booking City</span>
                                                <span>Delivery City</span>
                                                <span>Status</span>
                                                <span class="custom-field">Docket Current Status</span>
                                                <span class="w-10" *ngIf="docket?.pod">POD</span>
                                            </li>

                                            <li class="row-data" *ngFor="let docket of custMappData; let i = index">
                                                <span class="tracking"><a>{{docket?.docketNo}}</a></span>
                                                <span class="w-15">{{docket?.pickupDate | date:'dd/MM/yy'}}</span>
                                                <span class="custom-field">{{docket?.bookingCity | camelcase}}</span>
                                                <span>{{docket?.deliveryCity | camelcase}}</span>
                                                <span>{{docket?.docketStatus}}</span>
                                                <span class="custom-field">{{docket?.docketCurrentStatus |
                                                    camelcase}}</span>
                                                <span class="w-10" *ngIf="docket?.pod"><a *ngIf="docket?.pod" [href]="docket?.pod"><img
                                                            src="../../../../assets/img/icons/download.svg"
                                                            width="16px"></a></span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="row ">
                                        <div class="col-sm-4">
                                            <div class="shipment-details">
                                                <h2>Shipments Details</h2>
                                                <ul>
                                                    <li>
                                                        <b>Docket No.</b>
                                                        <span>{{docket?.docketNo}}</span>
                                                    </li>
                                                    <li>
                                                        <b>Order Date</b>
                                                        <span>{{docket?.createdAt | date:'dd/MM/yy'
                                                            }}</span>
                                                    </li>
                                                    <!-- <li>
                                                        <b>Order Number</b>
                                                        <span>{{docket?.orderNo}}</span>
                                                    </li>
                                                    <li *ngIf="orderData?.paymentMethod">
                                                        <b>Order type</b>
                                                        <span>{{orderData?.paymentMethod}}</span>
                                                    </li> -->
                                                    <li *ngIf="orderData?.deliveredDate">
                                                        <b>Est. Delivery Date</b>
                                                        <span>{{orderData?.deliveredDate | date:'dd/MM/yy'}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-sm-8 ">
                                            <div class="tracking-timeline">
                                                <h3>Shipment History</h3>

                                                <ng-container>
                                                    <div
                                                        *ngIf="docket?.statusDetailsList?.length > 0; else noTrackingMessage">
                                                        <div class="tracking"
                                                            *ngFor="let item of docket?.statusDetailsList | sortStatus">
                                                            <div class="left-icon">
                                                                <img [src]="getIcon(item?.vendorStatusMaster?.commonStatusName)">
                                                            </div>
                                                            <div class="right-content">
                                                                <h4>{{item?.vendorStatusMaster?.statusName}} </h4>
                                                                <h3>[{{item?.vendorStatusMaster?.commonStatusName}}] </h3>
                                                                <label *ngIf="item?.reasonDesc">{{item?.reasonDesc}} </label>
                                                                <p>{{item?.intransitDate }} {{item?.intransitTime}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-template #noTrackingMessage>
                                                        <p>shippment history is unvailable</p>
                                                    </ng-template>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>