import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as feather from 'feather-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';
import { MatDialog } from '@angular/material/dialog';
import { EditBranchDetailsComponent } from 'src/app/shared/component/edit-branch-details/edit-branch-details.component';
import { PageEvent } from '@angular/material/paginator';
import { MasterService } from 'src/app/shared/services/master.service';
import { PATTERNS } from 'src/app/constants/constants/constants';

@Component({
  selector: 'app-customer-branch',
  templateUrl: './customer-branch.component.html',
  styleUrls: ['./customer-branch.component.css']
})
export class CustomerBranchComponent {
  customerBranchForm: FormGroup | any;
  custId: any;
  customerBranchDetails: any[] | any;
  loader: boolean = false;
  totalCount: number = 0;
  pageSize = 2000;
  pageIndex = 0;
  pageEvent: any;
  customerFilter: any = [];

  states: any
  constructor(private route: ActivatedRoute, private http: HttpClient, private auth: AuthService,
    private fb: FormBuilder, public dialog: MatDialog, private masterService: MasterService) {
    feather.replace();

    this.customerBranchForm = this.fb.group({
      // Branch details
      branchName: ['', Validators.required],
      phoneNo: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      // state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      // erpId: ['', Validators.required],
      branchCode: ['', Validators.required],
      gst: ['', [Validators.required, Validators.pattern(PATTERNS.GSTIN)]],
      pan: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
      stateId: ['', [Validators.required]],
      status:['',Validators.required]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.custId = + params['id'];
      this.getCustomerBranchDetails(this.custId);
    });
    // State list
    this.getStateList()
  }

  pageNavigations(e: PageEvent) {
    this.pageEvent = e;
    this.totalCount = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  getCustomerBranchDetails(custId: any) {
    this.loader = true;
    this.masterService.getCustomerDetails(this.pageIndex, this.pageSize, this.customerFilter).subscribe((data: any) => {
      if (data != null) {
        const customerDetails = data?.responseObject?.content.find((cust: any) => cust.id === custId);
        if (customerDetails) {
          this.customerBranchDetails = customerDetails.branchDetailsList;
        } else {
         console.error('customer not found');
        }
      }
      this.loader = false;
    });
  }

  getStateList(): void {
    this.masterService.getStateDetails().subscribe((response: any) => {
      if (response && response.responseObject) {
        this.states = response.responseObject;
      }
    });
  }

  addCustomerBranchForm(): void {
    let dialogRef = this.dialog.open(AddDialogboxComponent, {
      width: '648px',
      data: {
        "component": "customerBranch",
        "customerBranchForm": this.customerBranchForm,
        "custId": this.custId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomerBranchDetails(this.custId);
    })
  }

  editCustomerBranchForm(data: any): void {
    this.customerBranchForm.setValue = data;
    let dialogRef = this.dialog.open(EditBranchDetailsComponent, {
      width: '648px',
      data: {
        "component": "customerBranch",
        "customerBranchForm": this.customerBranchForm,
        "custId": this.custId,
        states: this.states 
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomerBranchDetails(this.custId);
    })
  }
}
