import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as feather from 'feather-icons';
import { AddDialogboxComponent } from 'src/app/shared/component/add-dialogbox/add-dialogbox.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditBranchDetailsComponent } from 'src/app/shared/component/edit-branch-details/edit-branch-details.component';
import { PageEvent } from '@angular/material/paginator';
import { MasterService } from 'src/app/shared/services/master.service';
import { PATTERNS } from 'src/app/constants/constants/constants';
@Component({
  selector: 'app-vendor-branch',
  templateUrl: './vendor-branch.component.html',
  styleUrls: ['./vendor-branch.component.css']
})
export class VendorBranchComponent implements OnInit {
  vendorBranchForm: FormGroup | any;
  vendorId: any;
  vendorBranchDetails: any[] | any;
  loader: boolean = false;

  pageSize = 2000;
  totalCount: number = 0;
  pageIndex = 0;
  pageEvent: any;
  page!: PageEvent;
  allChecked: boolean = false;
  vendorFilter: any = [];
  states: any[] = [];

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private http: HttpClient, private auth: AuthService,
    private fb: FormBuilder, private masterService: MasterService) {
    feather.replace();

    this.vendorBranchForm = this.fb.group({
      // Branch details
      branchName: ['', Validators.required],
      phoneNo: ['', [Validators.required, Validators.pattern(/^[1-9]{1}[0-9]{9}$/)]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      // state: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      // erpId: ['', Validators.required],
      branchCode: ['', Validators.required],
      gst: ['', [Validators.required, Validators.pattern(PATTERNS.GSTIN)]],
      pan: ['', [Validators.required, Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)]],
      status: ['', Validators.required],
      stateId: ['', Validators.required],
      cwId: ['']
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.vendorId = + params['id'];
      this.getVendorBranchDetails(this.vendorId);
    });
    // State List
    this.getStateList();
  }

  pageNavigations(e: PageEvent) {
    // debugger
    this.pageEvent = e;
    this.totalCount = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;

    this.allChecked = false
    // this.getVendorBranchDetails(this.pageIndex, this.pageSize);
  }


  getVendorBranchDetails(vendorId: any) {

    console.log("Entered")
    this.loader = true;
    this.masterService.getVendorDetails(this.pageIndex, this.pageSize, this.vendorFilter).subscribe((data: any) => {
      if (data != null) {
        console.log(data);
        const vendorDetails = data?.responseObject?.content?.find((vendor: any) => vendor.id === vendorId);
        if (vendorDetails) {
          this.vendorBranchDetails = vendorDetails.branchDetailsList;
          console.log(this.vendorBranchDetails);
        } else {
          console.log("Vendor not found");
        }
      }
      this.loader = false;
    });
  }

  getStateList() {
    this.masterService.getStateDetails().subscribe((response: any) => {
      if (response && response.responseObject) {
        this.states = response.responseObject;
      }
    });
  }

  addVendorBranchForm(): void {
    let dialogRef = this.dialog.open(AddDialogboxComponent, {
      width: '648px',
      data: {
        "component": "vendorBranch",
        "vendorBranchForm": this.vendorBranchForm,
        "vendorId": this.vendorId
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getVendorBranchDetails(this.vendorId);
    })
  }

  editVendorBranchForm(data: any): void {
    this.vendorBranchForm.setValue = data;
    let dialogRef = this.dialog.open(EditBranchDetailsComponent, {
      width: '648px',
      data: {
        "component": "vendorBranch",
        "vendorBranchForm": this.vendorBranchForm,
        "vendorId": this.vendorId,
        states: this.states
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getVendorBranchDetails(this.vendorId);
    })
  }
}
